import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import iconAdv1 from "../images/ChainRestaurants/icon-adv-1.png";
import iconAdv2 from "../images/ChainRestaurants/icon-adv-2.png";
import iconAdv3 from "../images/ChainRestaurants/icon-adv-3.png";
import ContactForm from "../components/contactForm/form";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Integrations from "../components/integrations/integrations";
import News from './../components/news/news';
import Seo from "../components/seo";
import SingleDevice from "../components/singleDevice/singleDevice";
import Testimonials from "../components/testimonials/testimonials";
import Top from "../components/top/top";

import testimonialsState from "./../utils/testimonials";

// markup
const ChainRestaurantsPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 1,
    scrollToForm: scrollToForm,
    title: (
      <>
        Delivery Management & Optimization Across All Locations
        <br /> For Chain Restaurants
      </>
    ),
    subtitle: `The Orders.co suite was built to facilitate chain restaurants' needs like never before.
            Executives can review analytics from all connected locations from one unified administrator account.
            While Store Operators maintain full control over their individual locations.`,
    btnClasses: ["mt-xl-4"],
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="chain restaurants"
        src="../images/ChainRestaurants/chain-restaurants-orders.co.png"
        className="mw-100"
      />
    ),
  };

  const features = [
    {
      bg: "#DEFFEE",
      icon: iconAdv1,
      title: "Location Access",
      description:
        "Individual Store Operators maintain management control over their specific location.",
    },
    {
      bg: "#F1F7FF",
      icon: iconAdv2,
      title: "Corporate Management",
      description:
        "Admin user accounts have full access to all connected stores & brands.",
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Chain Reporting",
      description:
        "Consolidate online sales into one platform and review analytics across all connected stores.",
    },
  ];

  const singleDeviceProps = {
    title: `Easily manage your restaurant's online orders with a single device`,
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Restaurant Menu Management"
          src="../images/menu-management_tablet.png"
          className="mw-100"
        />
      </>
    ),
    scrollToForm: scrollToForm,
  };

  const testimonialsProps = {
    layout: 1,
    items: [
      testimonialsState.natalies_peruvian,
      testimonialsState.pops_artisanal_creamery,
      testimonialsState.c_viche,
      // testimonialsState.jonahs_kabob,
    ],
    title: "Hear From Our Customers",
    tag: "Testimonials",
  };

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Compare Revenue Across all Online Platforms",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Eliminate the hassle of collecting sales reports across multiple stores and platforms
            with Orders.co integrated reporting.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="sales revenue"
        src="../images/ChainRestaurants/sales-revenue.png"
      />
    ),
  };
  const imageTextProps2 = {
    layout: "equal-big",
    title: "Make Data-Driven Decisions",
    titleStyle: "smaller",
    imagePosition: "left",
    description: `Quickly generate reports to review top selling items, providers, and times of day.
            Making the tough decisions, the easy ones.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="data driven reporting"
        src="../images/ChainRestaurants/data-driven-report.png"
      />
    ),
  };
  const imageTextProps3 = {
    layout: "equal-big",
    title: "All Online Sales In One Place",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Corporate admin accounts have access to sales reporting data across all connected
            locations, brands, and concepts. Enabling decision makers to see the big picture in one
            dashboard and make the hard decisions easy.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="product online sales"
        src="../images/ChainRestaurants/online-sales-product.png"
      />
    ),
  };

  const questions = [
    {
      question: `How does Master Menu Management work with 100+ locations?`,
      answer: `Orders.co creates an Admin account with access to all connected locations.
                Menu updates can be made to all stores from one account.
                Individual locations will also have access to their own menus for changes.`,
    },
    {
      question: `How does Orders.co work with third party apps?`,
      answer: `Orders.co communicates with delivery apps in multiple ways.
                We receive order information while sending confirmation & cancellation info,
                we import and export menus, as well as sales/reports.`,
    },
    {
      question: `Do you provide the tablets to the restaurants you work with?`,
      answer: `Orders.co provides our All-In-One device; equipped with an integrated
                tablet and ticket printer.`,
    },
    {
      question: `How many locations or concepts can be connected?`,
      answer: `You can integrate as many locations and concepts as you need.`,
    },
    {
      question: `How will we receive analytics for all our stores?`,
      answer: `Admin accounts have access to reporting for all connected locations and concepts.
                You can view reports with custom filters for all locations/brands, providers,
                or even view specific days.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Chain Restaurants"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <div
          className="bg-mobile mt-xl-0 pt-xl-0"
          style={{
            backgroundColor: "#F7F7F7",
            marginTop: "-100px",
            paddingTop: "90px",
          }}
        >
          <Features title="Top Benefits" items={features}></Features>
        </div>
        <SingleDevice {...singleDeviceProps} />
        <Testimonials {...testimonialsProps} />
        <ImageText {...imageTextProps1} />
        <ImageText {...imageTextProps2} />
        <ImageText {...imageTextProps3} />
        <div
          className="bg-mobile"
          style={{
            background:
              "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
          }}
        >
          <Integrations scrollToForm={scrollToForm}></Integrations>
          <News items={data.allWpPost} />
          <Faq items={questions} />
          <div ref={formRef}>
            <ContactForm
              titleStyle="small"
              title={
                <>
                  Manage and Optimize
                  <br /> Delivery Across All
                  <br /> Locations
                </>
              }
            ></ContactForm>
          </div>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "chain-restaurants" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "chain-restaurants"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default ChainRestaurantsPage;
